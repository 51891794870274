*{
  box-sizing: border-box;
}
body {
  margin: 0;
  width: 100%;
  max-width: none;
}

main {
  width:100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.App-header {
  width: 100%;
  text-align: center;
}


.form-search {
  display: flex;
  justify-content: center;
  align-items: center;
}